input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.swiper-pagination-bullet {
  background-color: $slate-500 !important;
  opacity: 0.8;
}
.swiper-pagination-bullet-active {
  background-color: $blue-500 !important;
  opacity: 1;
}

.pagination {
  .page-link {
    border-top: 0;
    border-bottom: 0;
  }
  .page-item {
    &:last-child .page-link {
      border-right: 0;
    }
    &:first-child .page-link {
      border-left: 0;
    }
    &.disabled .page-link {
      @extend .text-muted;
    }
  }
}

.transition-fast {
  transition: all ease 0.2s;
}

.border-transparent {
  border-color: transparent !important;
}

// This is a hack specifically for fixing modal transitions
.transition-none {
  transition: none !important;
}

.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 0.3s;
}
