$nissan-brand: (
  "name": "nissan-brand",
  "styles": (
    "light": (
      path: "../../fonts/Nissan_Brand/Nissan_Brand_Light.otf",
      style: "normal",
      weight: "light",
    ),
    "regular": (
      path: "../../fonts/Nissan_Brand/Nissan_Brand_Regular.otf",
      style: "normal",
      weight: "regular",
    ),
    "italic": (
      path: "../../fonts/Nissan_Brand/Nissan_Brand_Italic.otf",
      style: "italic",
      weight: "regular",
    ),
    "bold": (
      path: "../../fonts/Nissan_Brand/Nissan_Brand_Bold.otf",
      style: "normal",
      weight: "bold",
    ),
  ),
);

$infiniti-brand: (
  "name": "infiniti-brand",
  "styles": (
    "light": (
      path: "../../fonts/InfinitiBrand/InfinitiBrand-Light.ttf",
      style: "normal",
      weight: "light",
    ),
    "regular": (
      path: "../../fonts/InfinitiBrand/InfinitiBrand-Regular.ttf",
      style: "normal",
      weight: "regular",
    ),
    "bold": (
      path: "../../fonts/InfinitiBrand/InfinitiBrand-Bold.ttf",
      style: "normal",
      weight: "bold",
    ),
  ),
);

$fonts: ($nissan-brand, $infiniti-brand);

@each $font in $fonts {
  $name: map-get($font, "name");
  .font-#{$name} {
    font-family: $name, sans-serif !important;
  }

  $styles: map-get($font, "styles");
  @each $key, $map in $styles {
    $path: map-get($map, "path");
    $style: map-get($map, "style");
    $weight: map-get($map, "weight");
    @font-face {
      font-family: $name;
      src: url($path) format("woff2"), url($path) format("woff"),
        url($path) format("truetype");
      font-weight: $weight;
      font-style: $style;
    }
  }
}
