@import "theme/variables"; // For writing variables

@import "~swiper/swiper";
@import "~swiper/components/pagination/pagination";

@import "theme/bootstrap-overrides"; // For overriding bootstrap default variables and styles

@import "~bootstrap/scss/bootstrap";

@import "theme/functions"; // For writing scss functions or hooking into bootstrap functions

@import "theme/fonts";

@import "theme/custom"; // Custom scss for this project
