@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $value in $sizes {
      .w#{$infix}-#{$size} {
        width: $value !important;
      }
      .h#{$infix}-#{$size} {
        height: $value !important;
      }
    }

    @each $size, $value in $font-sizes {
      .text#{$infix}-#{$size} {
        font-size: $value !important;
      }
    }
  }
}

@each $size, $value in $sizes {
  .top-#{$size} {
    top: $value !important;
  }
  .bottom-#{$size} {
    bottom: $value !important;
  }
  .left-#{$size} {
    left: $value !important;
  }
  .right-#{$size} {
    right: $value !important;
  }
  @if $value != "auto" and $value != 0 {
    .top-n#{$size} {
      top: -$value !important;
    }
    .bottom-n#{$size} {
      bottom: -$value !important;
    }
    .left-n#{$size} {
      left: -$value !important;
    }
    .right-n#{$size} {
      right: -$value !important;
    }
  }
  .min-h-#{$size} {
    min-height: $value !important;
  }
  .min-w-#{$size} {
    min-width: $value !important;
  }
  .max-h-#{$size} {
    max-height: $value !important;
  }
  .max-w-#{$size} {
    max-width: $value !important;
  }
}

@each $color, $value in $theme-colors {
  .btn-link-#{$color} {
    @extend .btn-link;
    color: $value;
    &:hover {
      color: darken($value, 10);
    }
    &:focus {
      text-decoration: none !important;
      box-shadow: 0 0 0 0.2rem rgba($value, 0.25);
    }
  }

  .border-top-#{$color} {
    border-top-color: $value;
  }
  .border-right-#{$color} {
    border-right-color: $value;
  }
  .border-bottom-#{$color} {
    border-bottom-color: $value;
  }
  .border-left-#{$color} {
    border-left-color: $value;
  }
}

@each $value in $object-fit {
  .object-#{$value} {
    object-fit: $value;
  }
}

@each $name, $value in $tracking {
  .tracking-#{$name} {
    letter-spacing: $value * 1em !important;
  }
}

@each $name, $value in $font-weights {
  .font-weight-#{$name} {
    font-weight: $value !important;
  }
}

@each $name, $value in $leading {
  .leading-#{$name} {
    line-height: $value !important;
  }
}

@each $name, $value in $opacity {
  .opacity-#{$name} {
    opacity: $value !important;
  }
}

@each $value in $indicies {
  .z-#{$value} {
    z-index: $value !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: $border-width solid;
    }
    .border#{$infix}-right {
      border-right: $border-width solid;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid;
    }
    .border#{$infix}-left {
      border-left: $border-width solid;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }
  }
}

@each $name, $value in $border-widths {
  .border-w-#{$name} {
    border-width: $value !important;
  }
}
