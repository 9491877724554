$primary: $blue-500;
$secondary: $slate-500;

$shadow-color: $slate-800;

$input-placeholder-color: $slate-500;

$border-color: $slate-100;

$dropdown-link-hover-bg: lighten(desaturate($blue-50, 2), 3);

$pagination-border-color: $slate-100;
$pagination-disabled-border-color: $slate-200;
$pagination-hover-bg: lighten(desaturate($blue-50, 2), 3);
$pagination-active-border-color: $blue-100;
$pagination-active-bg: $blue-100;
$pagination-active-color: $blue-700;

$box-shadow: 0 0.5rem 1rem rgba($shadow-color, 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($shadow-color, 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($shadow-color, 0.3) !default;
$box-shadow-inset: inset 0 1px 2px rgba($shadow-color, 0.075) !default;
